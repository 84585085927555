<template>
  <div
    v-if="visible"
    :id="id"
    class="base-spinner"
    :style="[ currentBackground ]"
    align-self="center"
  >
    <div class="base-spinner__area">
      <v-progress-circular
        :value="percentage"
        :size="size"
        indeterminate
        color="#ffffff"
      />
      <p class="base-spinner__area--message mt-3">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
import { convertHexToRGBA } from '@helpers/functions'

export default {
  name: 'BaseSpinner',
  props: {
    id: {
      type: String,
      required: true,
      validator: (value) => {
        if (!value) return false
        return value
      }
    },
    message: {
      type: String,
      default: ''
    },
    percentage: {
      type: Number,
      default: null
    },
    size: {
      type: String,
      default: '32'
    },
    indeterminate: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value)
      }
    },
    visible: {
      type: Boolean,
      default: false,
      validator: (value) => {
        return [true, false].includes(value)
      }
    },
    background: {
      type: Boolean,
      default: true,
      validator: (value) => {
        return [true, false].includes(value)
      }
    },
    backgroundColor: {
      type: String,
      default: '#000000',
      validator: (value) => {
        const hex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/

        const validation = hex.test(value)

        if (!validation) return false
        return value
      }
    },
    textColor: {
      type: String,
      default: '#ffffff'
    }
  },
  computed: {
    currentBackground () {
      if (!this.background) return {}

      return {
        color: this.textColor,
        background: convertHexToRGBA(this.backgroundColor, 0.7)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.base-spinner {
  position:fixed;
  width:100%;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index: 999999;

  &__area {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    opacity: 1;

    &--message {
      font-weight: bold;
      font-size: 34px;
      opacity: 1;
    }
  }
}
</style>
